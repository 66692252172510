import { useContext, useEffect, useState } from 'react';

import { DashboardContext } from '../components/DashboardLayout';

const defaultParams = {
    displayLogo: false,
    qrStyle: 'squares',
    bgColor: '#FFFFFF',
    fgColor: '#000000'
};

const useQrCodeParams = () => {
    const { store, storeDoc, createOrUpdateStore, isLoading, isLoadingMutation } =
        useContext(DashboardContext);
    const [displayParams, setDisplayParams] = useState(defaultParams);
    const [hasInit, setHasInit] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const initParams = async () => {
        await createOrUpdateStore({ qrCodeParams: displayParams });
        setHasInit(true);
    };

    useEffect(() => {
        if (!storeDoc || hasInit) return;
        if (storeDoc && !store.qrCodeParams) {
            initParams();
            return;
        }
        setDisplayParams(store.qrCodeParams);
        setTimeout(() => setHasInit(true), 200);
    }, [store, storeDoc]);

    useEffect(() => {
        if (!hasInit) return;
        clearTimeout(timeoutId);
        const id = setTimeout(async () => {
            await createOrUpdateStore({ qrCodeParams: displayParams });
        }, 2500);
        setTimeoutId(id);
        return () => clearTimeout(id);
    }, [displayParams]);

    const qrCodeProps = {
        ...(displayParams.displayLogo && {
            logoImage: store.logo.image,
            removeQrCodeBehindLogo: true
        }),
        qrStyle: displayParams.qrStyle,
        eyeRadius: displayParams.qrStyle == 'dots' ? 5 : 0,
        bgColor: displayParams.bgColor,
        fgColor: displayParams.fgColor,
        enableCORS: true
    };

    return { displayParams, qrCodeProps, setDisplayParams, isLoading, isLoadingMutation };
};

export { useQrCodeParams };
