import React from "react";
import {Link} from "gatsby";

import Modal from "../Modal";
import catAstronautImg from '../../images/cat-astronaut.png'

const BePremiumModal = ({
                            isOpen = false,
                            setIsOpen,
                            text,
                        }) => {
    const handleGoBack = () => {
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={handleGoBack}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className={"ml-3 mt-3"}>
                        <button
                            type="reset"
                            onClick={handleGoBack}
                            className={`bg-white text-black h-10 w-10 rounded-full cursor-pointer outline-none`}>
                            <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                    <div className='overflow-y-auto' style={{maxHeight: '42rem'}}>
                        <section className="p-10 flex flex-col items-center justify-center">
                            <div className="pb-3 flex flex-col items-center justify-center">
                                <img src={catAstronautImg} alt={'be premium image'}/>
                                <h3 className="mt-10 text-gray-700 uppercase font-semibold text-xl text-center">{text}</h3>
                            </div>
                            <Link href={'/app/pricing'}>
                                <button
                                    type="button"
                                    className="flex flex-row items-center text-white border bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xl px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
                                    Mettre à niveau 🚀
                                </button>
                            </Link>
                        </section>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BePremiumModal;
