import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {StaticQuery, graphql, navigate, Link} from "gatsby";

import AuthModal from "./AuthModal";
import logoImg from "../images/logo.png";
import {getFirebase} from "../utils/signin";
import Footer from "./footer";
import {isNilOrEmpty} from "../utils";

export const MyMenusTab = 'MyMenusTab';
export const myProjectsTab = 'myProjectsTab';
export const accountTab = 'accountTab';

function Signedlayout({children, activeTab}) {
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const firebase = getFirebase();
    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            const db = getFirebase().firestore();
            db.collection("customers")
                .doc(user.uid)
                .collection("subscriptions")
                .where("status", "in", ["trialing", "active"])
                .onSnapshot(async (snapshot) => {
                    if (snapshot.empty) {
                        getFirebase().auth().signOut();
                        navigate('/');
                        return;
                    }
                });
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handlerRedirectToMyMenus = () => {
        navigate('my-menus');
    }
    const signInSuccessHandler = (authResult, redirectUrl) => {
        handlerRedirectToMyMenus();
    }
    const handleOpenAuthModal = () => {
        if (isSignedIn) {
            handlerRedirectToMyMenus();
            return;
        }

        setIsOpenAuthModal(true);
    }
    const activeTabMaybe = 'font-bold no-underline';
    const disableTabMaybe = 'hover:text-gray-800 hover:text-underline';
    if (isNilOrEmpty(firebase)) return null;

    return (
        <StaticQuery
            query={graphql`
        query SignedSiteTitleQuery {
          site {
            siteMetadata {
              title,
              github,
              blog
            }
          }
        }
      `}
            render={(data) => (
                <body className="leading-normal tracking-normal  flex flex-col">


                {/* Navigation */}
                <nav id="header" className="fixed w-full z-30 top-0 text-white bg-white shadow">

                    <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">

                        <div className="pl-4 flex items-center">
                            <a className="toggleColour no-underline hover:no-underline font-bold text-xl lg:text-4xl text-gray-800"
                               href="/">
                                <img className="h-16 fill-current inline" src={logoImg}/>eMenila
                            </a>
                        </div>

                        <div className="block lg:hidden pr-4">
                            <button id="nav-toggle"
                                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 bg-white">
                                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                                </svg>
                            </button>
                        </div>

                        <div
                            className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                            id="nav-content">
                            <ul className="list-reset lg:flex justify-end flex-1 items-center">
                                {/*<li className="mr-3">*/}
                                {/*    <a className={`inline-block py-2 px-2 text-black ${activeTab === MyMenusTab ? activeTabMaybe : disableTabMaybe}`}*/}
                                {/*       href="/my-products">Mes Produits</a>*/}
                                {/*</li>*/}
                                <li className="mr-3">
                                    <a className={`inline-block py-2 px-2 text-black ${activeTab === myProjectsTab ? activeTabMaybe : disableTabMaybe}`}
                                       href="/app">Mes Menus</a>
                                </li>
                                <li className="mr-3">
                                    <a className={`inline-block py-2 px-2 text-black ${activeTab === accountTab ? activeTabMaybe : disableTabMaybe}`}
                                       href="/account">Mon compte</a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>
                </nav>
                <AuthModal signInSuccessHandler={signInSuccessHandler} isOpen={isOpenAuthModal}
                           setIsOpen={setIsOpenAuthModal}/>

                <div className='flex-1'>
                    {children}
                </div>

                {/* Footer */}
                <Footer/>
                </body>
            )}
        />
    );
}

Signedlayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Signedlayout;
