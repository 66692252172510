import { useContext, useEffect, useState } from 'react';

import { DashboardContext } from '../components/DashboardLayout';
import { getFirebase } from '../utils/signin';

const useTables = () => {
    const db = getFirebase().firestore();
    const { storeDoc } = useContext(DashboardContext);
    const tableCollection = storeDoc?.collection('tables');
    const [tables, setTables] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);

    const addTable = async () => {
        setIsLoadingMutation(true);
        const querySnapshot = await tableCollection.get();
        await tableCollection.add({ id: querySnapshot.docs.length + 1, views: 0 });
        setIsLoadingMutation(false);
    };

    const generateTables = async ({ count }) => {
        setIsLoading(true);
        const batch = db.batch();
        const array = new Array(parseInt(count)).fill(0);

        array.forEach((value, index) => {
            const docRef = tableCollection.doc();
            batch.set(docRef, { id: index + 1, views: 0 });
        });

        await batch.commit();
        syncTables();
        setIsLoading(false);
    };

    const syncTables = async () => {
        setIsLoading(true);
        const querySnapshot = await tableCollection.get();
        setTables(
            querySnapshot.docs
                .map((table) => ({ uid: table.id, ...table.data() }))
                .sort(({ id: idA }, { id: idB }) => idA - idB)
        );
        setIsLoading(false);
    };

    useEffect(() => {
        if (!storeDoc) return;
        syncTables();
    }, [storeDoc]);

    return { tables, addTable, syncTables, isLoading, isLoadingMutation, generateTables };
};

export { useTables };
