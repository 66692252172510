import React, { createContext } from 'react';
import { useUser } from '../hooks';

const initialState = {
    user: null,
    isSignedIn: false,
};

const AppContext = createContext(initialState);

const AppProvider = ({ children }) => <AppContext.Provider value={{ ...useUser() }}>{children}</AppContext.Provider>;
export default AppContext;

export { AppProvider };
