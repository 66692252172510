import React, { useContext, createContext, Fragment, useEffect, useState } from 'react';
import { find, includes, isEmpty, last, propEq, reject, slice } from 'ramda';
import { Dialog, Transition } from '@headlessui/react';
import {
    WalletIcon,
    Bars3Icon,
    BuildingStorefrontIcon,
    ListBulletIcon,
    RectangleStackIcon,
    QrCodeIcon,
    UserIcon,
    XMarkIcon,
    ArrowTopRightOnSquareIcon,
    ChevronRightIcon,
    CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { Crisp } from 'crisp-sdk-web';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';

import AppContext from '../context/app';
import { useStore } from '../hooks';
import discountBgImg from '../images/discount-bg.png';
import logoImg from '../images/logo.png';
import BePremiumModal from './BePremiumModal';
import SEO from './seo';

const navigation = [
    { name: 'Restaurant', path: '/', icon: BuildingStorefrontIcon },
    { name: 'Menus', path: '/menus', icon: ListBulletIcon },
    { name: 'QRCodes', path: '/qrcodes', icon: QrCodeIcon },
    { name: 'Commandes', path: '/orders', icon: RectangleStackIcon },
    { name: 'Carte de Fidélité', path: '/digital-card', icon: WalletIcon },
    { name: 'Tarifs', path: '/pricing', icon: WalletIcon, isNotVisible: true },
    { name: 'Économisez 50% sur votre abonnement', path: '/referral-program', icon: CurrencyDollarIcon },
    { name: 'Mon compte', path: '/account', icon: UserIcon }
];

export const DashboardContext = createContext({
    store: null,
    storeDoc: null,
    isLoading: false,
    isLoadingMutation: false
});

Crisp.configure(process.env.GATSBY_CRISP_WEBSITE_ID, { autoload: false });

const DashboardLayout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [hideLayout, setHideLayout] = useState(false);
    const [noStoreError, setNoStoreError] = useState(false);
    const [canShowLaunchOfferMessage, setCanShowLaunchOfferMessage] = useState(false);
    const {
        store,
        storeDoc,
        createOrUpdateStore,
        getLoyaltyCard,
        stampAppleLoyaltyCard,
        isLoading,
        isLoadingMutation
    } = useStore();
    const { userLimits } = useContext(AppContext);
    const { pathname } = useLocation();
    const getCurrentRoute = () => {
        const path = last(slice(0, 2, reject(isEmpty, pathname.split('/'))));
        return find(propEq('path', `/${path == 'app' ? '' : path}`), navigation);
    };
    const pageName = getCurrentRoute()?.name;
    const currentPageIsPricingPage = pageName === 'Tarifs';
    useEffect(() => {
        Crisp.load();
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setSidebarOpen(false);
        }, 200);
    }, [pathname]);
    useEffect(() => {
        if (!userLimits?.isTrial) return;

        setTimeout(() => {
            setCanShowLaunchOfferMessage(true);
        }, 2000);
    }, [userLimits?.isTrial]);
    const canImproveOfferPlan =
        !userLimits?.onlineOrderEnabled ||
        !userLimits?.loyaltyCardEnabled ||
        userLimits?.numberOfMenuItems < 50 ||
        !userLimits?.images;
    const handleNoStoreCreated = () => {
        setNoStoreError(true);
        setTimeout(() => {
            setNoStoreError(false);
        }, 15000);
    };
    const handleHideNoStoreCreatedError = () => {
        setNoStoreError(false);
    };

    const links = navigation.map(({ name, path, icon: Icon, isNotVisible }) => {
        if (isNotVisible) return null;

        const link = `/app${path}`;
        const isCurrent = pageName == name;
        const isDisabledLink = !includes(path, ['/', '/account']) && !storeDoc;
        const linkClasses = classNames(
            'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
            {
                'text-gray-400 hover:bg-gray-50 hover:text-gray-400': isDisabledLink,
                'bg-gray-100 text-gray-900': isCurrent,
                'text-gray-600 hover:bg-gray-50 hover:text-gray-900': !isCurrent && !isDisabledLink
            }
        );

        const iconClasses = classNames('mr-4 flex-shrink-0 h-6 w-6', {
            'text-gray-500': isCurrent,
            'text-gray-400 group-hover:text-gray-500': !isCurrent
        });
        if (isDisabledLink)
            return (
                <div onClick={handleNoStoreCreated} key={name} className={linkClasses}>
                    <Icon className={iconClasses} aria-hidden="true" />
                    {name}
                </div>
            );

        return (
            <Link to={link} key={name} className={linkClasses}>
                <Icon className={iconClasses} aria-hidden="true" />
                {name}
            </Link>
        );
    });

    const disableLayout = () => {
        setHideLayout(true);
    };

    const enableLayout = () => {
        setHideLayout(false);
    };

    const storeInfo = store && (
        <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
            <Link to="/app" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                    <div>
                        {store?.logo?.image && (
                            <img className="inline-block h-9 w-9 rounded-full" src={store?.logo?.image} />
                        )}
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                            {store.name}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );

    const contextValues = {
        store,
        storeDoc,
        createOrUpdateStore,
        getLoyaltyCard,
        stampAppleLoyaltyCard,
        isLoading,
        isLoadingMutation,
        disableLayout,
        enableLayout
    };

    if (hideLayout)
        return (
            <DashboardContext.Provider value={contextValues}>
                <div className="bg-white">{children}</div>
            </DashboardContext.Provider>
        );

    return (
        <DashboardContext.Provider value={contextValues}>
            <body className="bg-white">
                <SEO title="Mon espace pro" />
                {canShowLaunchOfferMessage && !currentPageIsPricingPage && (
                    <Link to={'/app/pricing'}>
                        <div
                            className="flex flex-nowrap whitespace-nowrap overflow-hidden justify-end fixed animate-bounce-short z-50 left-0 right-0 w-full p-2 text-xl text-white bg-black border-b border-b-2 border-white text-center"
                            role="alert"
                        >
                            <div className="animate-slide-left animation-play-state flex flex-shrink-0 items-center text-center">
                                <span className="font-medium pr-2">🔥 Offre de lancement ! </span> Profitez de{' '}
                                <span className="text-white bg-red-600 p-1 text-sm rounded-lg mx-2">
                                    -40%
                                </span>{' '}
                                de remises offert sur les abonnements de nos premiers clients. 🚀
                            </div>
                        </div>
                    </Link>
                )}
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                        <div className="flex flex-shrink-0 items-center px-4">
                                            <img className="h-8 w-auto" src={logoImg} alt="eMenila" />
                                        </div>
                                        <nav className="mt-5 space-y-1 px-2">{links}</nav>
                                    </div>
                                    {storeInfo}
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="w-14 flex-shrink-0">
                                {/* Force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                    {canShowLaunchOfferMessage && !currentPageIsPricingPage && <div className="pt-10" />}
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                            <div className="flex flex-shrink-0 items-center px-4">
                                <img className="h-8 w-auto" src={logoImg} alt="eMenila" />
                            </div>
                            <nav className="mt-5 flex-1 space-y-1 bg-white px-2">{links}</nav>
                        </div>
                        <div className="relative border border-gray-200 m-4 rounded-md">
                            {(canImproveOfferPlan || userLimits?.isTrial) && (
                                <div className="absolute -top-3 right-0 rounded-full h-4 w-4 bg-green-700 animate-pulse mt-1 mr-2" />
                            )}
                            <div className="p-4">
                                {userLimits?.isTrial && (
                                    <div>
                                        <div className="text-gray-800 font-semibold text-md">
                                            Abonnement actuel: Essai Gratuit
                                        </div>
                                        <div className="text-gray-600 font-light text-xs flex">
                                            {/*{userLimits?.remainingTrialDays < 3 && (*/}
                                            {/*    <div className='rounded-full h-4 w-4 bg-green-700 animate-pulse mt-1 mr-2'/>*/}
                                            {/*)}*/}
                                            Reste {userLimits?.remainingTrialDays} jours d'essai
                                        </div>
                                    </div>
                                )}
                                {!userLimits?.isTrial && canImproveOfferPlan && (
                                    <div>
                                        <div className="text-gray-800 font-semibold text-md flex">
                                            Plus de capacité sont disponible:
                                        </div>
                                        {!userLimits?.images && (
                                            <div className="text-gray-600 font-light text-xs">
                                                Ajoutez une photo par article de menu
                                            </div>
                                        )}
                                        {userLimits?.numberOfMenuItems < 50 && (
                                            <div className="text-gray-600 font-light text-xs">
                                                Ajoutez plus de lignes à vos menus
                                            </div>
                                        )}
                                        {!userLimits?.onlineOrderEnabled && (
                                            <div className="text-gray-600 font-light text-xs">
                                                Activez les commandes en ligne
                                            </div>
                                        )}
                                        {!userLimits?.loyaltyCardEnabled && (
                                            <div className="text-gray-600 font-light text-xs">
                                                Activez la carte de fidélité digitale
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="border-t border-gray-200 py-3 px-4">
                                <Link to={'/app/pricing'}>
                                    <div className="text-sapin-500 flex justify-between items-center cursor-pointer hover:text-sapin-600 hover:font-semibold">
                                        🚀 Mettre à niveau{' '}
                                        <ChevronRightIcon
                                            className="ml-2 flex-shrink-0 h-4 w-4 font-semibold"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        {storeInfo}
                    </div>
                </div>
                <div className="flex flex-1 flex-col lg:pl-64">
                    {canShowLaunchOfferMessage && !currentPageIsPricingPage && <div className="pt-10" />}
                    <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <main
                        className={classNames('flex-1', currentPageIsPricingPage && 'bg-sapin-200')}
                        style={
                            currentPageIsPricingPage
                                ? {
                                      backgroundImage: `url("${discountBgImg}")`
                                      // backgroundSize: '30%'
                                  }
                                : {}
                        }
                    >
                        <div className="py-6">
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 flex md:flex-row flex-col gap-2 justify-between">
                                {!currentPageIsPricingPage && (
                                    <h1 className="text-2xl font-semibold text-gray-900">{pageName}</h1>
                                )}
                                {store?.slug && !currentPageIsPricingPage && (
                                    <Link href={`/stores/${store?.slug}`} target="_blank">
                                        <button
                                            type="button"
                                            className="flex flex-row items-center text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
                                        >
                                            Voir ma page publique{' '}
                                            <ArrowTopRightOnSquareIcon
                                                className="ml-1 flex-shrink-0 h-4 w-4"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </Link>
                                )}
                            </div>
                            <div className="mx-auto max-w-7xl pt-10 px-4 sm:px-6 md:px-8">{children}</div>
                            <BePremiumModal
                                isOpen={
                                    !currentPageIsPricingPage &&
                                    pageName !== 'Mon compte' &&
                                    userLimits?.isTrial &&
                                    userLimits?.remainingTrialDays <= 0
                                }
                                setIsOpen={() => {
                                    navigate('/app/pricing');
                                }}
                                text="Votre période d'essai est déjà terminée"
                            />
                        </div>
                    </main>
                    {noStoreError && (
                        <div
                            className="animate-bounce-short bg-black border text-white px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                            role="alert"
                        >
                            {/*<strong className="font-bold">A! </strong>*/}
                            <div className="pr-10">
                                Veuillez enregistrer votre restaurant pour pouvoir créer vos menus et accéder
                                au QR code, commandes en lignes et à la carte de fidélité digitale.
                            </div>
                            <span
                                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                onClick={handleHideNoStoreCreatedError}
                            >
                                <svg
                                    className="fill-current h-6 w-6 text-white"
                                    role="button"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <title>Fermer</title>
                                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                            </span>
                        </div>
                    )}
                </div>
            </body>
        </DashboardContext.Provider>
    );
};

export default DashboardLayout;
