import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { isNilOrEmpty } from '../../utils';
import { getFirebase, getUiConfig } from '../../utils/signin';
import Modal from '../Modal';

const AuthModal = ({ isOpen = false, setIsOpen, signInSuccessHandler = Function.prototype }) => {
    const { t, i18n } = useTranslation('firebase');
    const isEnLanguage = i18n.language == 'en';
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    // Listen to the Firebase Auth state and set the local state.
    const firebase = getFirebase();
    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    if (isNilOrEmpty(firebase)) return null;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-2xl font-large leading-6 text-gray-900">
                    {t('auth.title')}
                </Dialog.Title>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">{t('auth.desc')}</p>
                    <div className="p-4">
                        <StyledFirebaseAuth
                            uiConfig={getUiConfig(
                                signInSuccessHandler,
                                isEnLanguage ? null : t('googleSignIn')
                            )}
                            firebaseAuth={getFirebase().auth()}
                        />
                    </div>
                </div>

                <div className="mt-4">
                    <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-sapin-900 bg-sapin-200 border border-transparent rounded-md hover:bg-sapin-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClose={() => setIsOpen(false)}
                        onClick={() => setIsOpen(false)}
                    >
                        {t('close')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AuthModal;
