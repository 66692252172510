import React, {useContext, useEffect, useState} from "react";
import {map, last, pathOr, length, find} from "ramda";
import moment from "moment";

import Modal from "../Modal";
import {isNilOrEmpty} from "../../utils";
import ConsumerAuthModal from "../ConsumerAuthModal";
import AppContext from "../../context/app";
import {getOrderStateDescription, getOrderStateLabel} from "../../hooks/orders";

const CartModal = ({
                       cart,
                       storePhoneNumber,
                       isOpen = false,
                       setIsOpen,
                       primaryColor,
                       isClosedOrdersOnline,
                       handleOpenDetailsMenuItemModal,
                       handleSubmitOrder,
                       myOrders = [],
                   }) => {
    const {isSignedIn, user} = useContext(AppContext);
    const cartItems = pathOr([], ['items'], cart);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isOrderDetailsView, setIsOrderDetailsView] = useState(false);
    const [hasJustSubmit, setHasJustSubmit] = useState(false);
    useEffect(() => {
        if (!isOpen || !myOrders.length || !isNilOrEmpty(cartItems) || (myOrders.length > 1 && !hasJustSubmit)) return;

        setSelectedOrder(last(myOrders));
        setHasJustSubmit(false);
    }, [isOpen, myOrders.length, cartItems]);
    useEffect(() => {
        if (!isOpen || !myOrders || !selectedOrder) return;

        const selectedOrderRefreshed = find(({id}) => id === selectedOrder.id, myOrders);
        navigator.vibrate([100, 100]);
        setSelectedOrder(selectedOrderRefreshed);
    }, [myOrders]);
    useEffect(() => {
        if (isOpen) return;

        setSelectedOrder(null);
        setIsOrderDetailsView(false);
    }, [isOpen]);
    const signInSuccessHandler = (authResult, redirectUrl) => {
        setIsOpenAuthModal(false);
    }
    const handleOpenAuthModal = () => {
        setIsOpenAuthModal(true);
    }
    const handleSubmitCart = () => {
        if (!isSignedIn) {
            handleOpenAuthModal();
            return;
        }

        handleSubmitOrder(cart);
        setHasJustSubmit(true);
    }
    const handleGoBack = () => {
        setIsOpen(false);
    }
    const handleSelectOrder = (order) => {
        setSelectedOrder(order);
        setIsOrderDetailsView(true);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={handleGoBack}>
            <ConsumerAuthModal signInSuccessHandler={signInSuccessHandler} isOpen={isOpenAuthModal}
                               setIsOpen={setIsOpenAuthModal}/>
            <div
                className="absolute bottom-0 left-0 md:relative inline-block w-full max-w-md overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-t-2xl md:rounded-2xl">
                <div className="relative sm:max-w-xl sm:mx-auto">
                    <div className={"ml-3 mt-3"}>
                        <button
                            type="reset"
                            onClick={handleGoBack}
                            className={`bg-white text-black h-10 w-10 rounded-full cursor-pointer outline-none`}>
                            <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                    <div className='overflow-y-auto' style={{maxHeight: '42rem'}}>
                        {!selectedOrder && !isNilOrEmpty(cartItems) && (
                            <section>
                                <div className="px-4 pb-3">
                                    <h2 className="text-gray-700 uppercase font-semibold">Ma commande</h2>
                                </div>
                                <div className="relative px-4 pb-10 bg-white md:mx-0 sm:py-10">
                                    <div className="max-w-md mx-auto">
                                        <div className="w-full max-w-sm mx-auto rounded-md relative overflow-hidden">
                                            {map(({menuItem, note, quantity: orderQuantity}) => {
                                                const {image, title, price, quantity, description} = menuItem;

                                                return (
                                                    <div
                                                        onClick={() => handleOpenDetailsMenuItemModal(menuItem)}
                                                        className="py-3 w-full flex flex-row items-start justify-start max-w-sm mx-auto rounded-sm relative overflow-hidden"
                                                    >
                                                        {image && (
                                                            <div
                                                                className="flex items-end justify-end h-20 w-20 lg:h-56 bg-cover mr-5"
                                                                style={{backgroundImage: `url("${image}")`}}>
                                                            </div>
                                                        )}
                                                        <div className="pr-5 pb-3">
                                                            <h3 className="text-gray-700 uppercase font-semibold"
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: "2",
                                                                    WebkitBoxOrient: "vertical"
                                                                }}>{title}</h3>
                                                            <span className="text-gray-700 text-sm">{price} €</span>
                                                            {orderQuantity && (
                                                                <span
                                                                    className="text-gray-500 text-sm"> • {orderQuantity}x</span>
                                                            )}
                                                            <div>
                                                                <span className="text-gray-500 text-sm">{note}</span>
                                                            </div>
                                                            <button
                                                                onClick={() => handleOpenDetailsMenuItemModal(menuItem)}
                                                                className="mx-auto lg:mx-0 transform hover:scale-105 hover:font-bold underline mt-2">
                                                                Modifier
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }, cartItems)}
                                        </div>

                                        {!isNilOrEmpty(cartItems) && (
                                            <div className="py-4 flex flex-col justify-center items-center space-x-2">
                                                <button
                                                    disabled={isClosedOrdersOnline}
                                                    onClick={handleSubmitCart}
                                                    className={`${primaryColor} text-white flex justify-center items-center w-72 px-4 py-3 rounded-md focus:outline-none`}
                                                >Valider ma commande
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}
                        {selectedOrder && (
                            <section className="pb-10">
                                <div className="px-4 pb-3 text-center">
                                    <p className="text-gray-700 font-semibold">{getOrderStateDescription(selectedOrder.state)}</p>
                                </div>
                                <div className="px-4 pb-3 flex flex-col items-center justify-center">
                                    <span className="text-gray-500 text-xl">n°</span>
                                    <h1 className="text-gray-700 uppercase font-semibold text-7xl">{selectedOrder.displayId}</h1>
                                </div>
                                <div className="px-4 pb-3 flex flex-col items-center justify-center">
                                    <div className="px-4 py-2 bg-blue-200">
                                        <p className="text-blue-600 text-xs uppercase">{getOrderStateLabel(selectedOrder.state)}</p>
                                    </div>
                                </div>
                                <div className="pt-4 w-full max-w-sm mx-auto rounded-md relative overflow-hidden">
                                    {map(({menuItem, note, quantity: orderQuantity}) => {
                                        const {image, title, price, quantity, description} = menuItem;

                                        return (
                                            <div
                                                className="py-3 w-full flex flex-row items-start justify-start max-w-sm mx-auto rounded-sm relative overflow-hidden"
                                            >
                                                {image && (
                                                    <div
                                                        className="flex items-end justify-end h-16 w-16 lg:h-56 bg-cover mr-5"
                                                        style={{backgroundImage: `url("${image}")`}}>
                                                    </div>
                                                )}
                                                <div className="pr-5 pb-3">
                                                    <h3 className="text-gray-700 text-sm uppercase font-semibold"
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "2",
                                                            WebkitBoxOrient: "vertical"
                                                        }}>{title}</h3>
                                                    <span className="text-gray-700 text-xs">{price} €</span>
                                                    {orderQuantity && (
                                                        <span
                                                            className="text-gray-500 text-xs"> • {orderQuantity}x</span>
                                                    )}
                                                    <div>
                                                        <span className="text-gray-500 text-xs">{note}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }, selectedOrder.items)}
                                </div>
                                <div className='flex items-center justify-center pt-4'>
                                    <span className="text-gray-500 text-xs">N° de téléphone à contacter en cas d'urgence : </span><a
                                    href={`tel:${storePhoneNumber}`}
                                    className="text-gray-600 text-sm ml-1 underline"> {storePhoneNumber}</a>
                                </div>
                            </section>
                        )}
                        {length(myOrders) > 1 && !selectedOrder && isNilOrEmpty(cartItems) && (
                            <section>
                                <div className="px-4 pb-3">
                                    <h2 className="text-gray-700 uppercase font-semibold">Mes commandes en cours</h2>
                                </div>
                                <div className="relative px-4 pb-10 bg-white md:mx-0 sm:py-10">
                                    <div className="max-w-md mx-auto">
                                        <div className="w-full max-w-sm mx-auto rounded-md relative overflow-hidden">
                                            {map((order) => {
                                                const {displayId, createdAt, state, total} = order;
                                                return (
                                                    <div
                                                        onClick={() => handleSelectOrder(order)}
                                                        className="py-3 w-full flex flex-row items-start justify-start max-w-sm mx-auto rounded-sm relative overflow-hidden"
                                                    >
                                                        <div className='w-2 h-28 bg-blue-600 mr-2 rounded-xl'/>
                                                        {/*{image && (*/}
                                                        {/*    <div*/}
                                                        {/*        className="flex items-end justify-end h-32 w-32 lg:h-56 bg-cover mr-5"*/}
                                                        {/*        style={{backgroundImage: `url("${image}")`}}>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                        <div className="pr-5 pb-3">
                                                            <h3 className="text-gray-700 uppercase font-semibold"
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: "2",
                                                                    WebkitBoxOrient: "vertical"
                                                                }}>n° {displayId}</h3>
                                                            <span className="text-gray-700 text-sm">{total} €</span>
                                                            <div>
                                                            <span
                                                                className="text-gray-700 text-sm">{moment(createdAt).format('LT')}</span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="text-gray-500 text-sm">{getOrderStateLabel(state)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }, myOrders)}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CartModal;
