import React, { useEffect, useRef, useState } from 'react';

import { getTotal } from '../../client/store';
import Modal from '../Modal';

const DetailsMenuItemModal = ({
    handleSaveMenuItemToCart,
    orderOnlineEnabled,
    menuItem,
    orderItem,
    isOpen = false,
    setIsOpen,
    primaryColor,
    isClosedOrdersOnline
}) => {
    const formRef = useRef();
    console.log('orderItem', orderItem);
    const [orderQuantity, setOrderQuantity] = useState(orderItem?.quantity ?? 0);
    useEffect(() => {
        console.log('menuItem?.id, formRef.current, isOpen', menuItem?.id, formRef.current, isOpen);
        formRef.current?.reset();
        setOrderQuantity(orderItem?.quantity ?? 0);
    }, [menuItem?.id, isOpen]);
    const handleGoBack = () => {
        setIsOpen(false);
        formRef.current?.reset();
    };
    const handleNewMenuItemToCart = async (event) => {
        event.preventDefault();
        // if (!userId || !isSignedIn) return 'SHOW ERROR';getTotal(menuItem.price, orderQuantity)

        const note = event.target.note.value;
        await handleSaveMenuItemToCart(menuItem, orderQuantity, note);
        handleGoBack();
    };
    const handleRemoveMenuItem = async (event) => {
        event.preventDefault();
        await handleSaveMenuItemToCart(menuItem, 0, '');
        handleGoBack();
    };
    const handleDecrementQuantity = (event) => {
        event.preventDefault();
        if (orderQuantity <= 0) return;

        setOrderQuantity(orderQuantity - 1);
    };

    const handleIncrementQuantity = (event) => {
        event.preventDefault();
        console.log('handleIncrementQuantity', orderQuantity);
        setOrderQuantity(orderQuantity + 1);
    };
    if (!menuItem) return null;

    const { title, description, image, price, quantity } = menuItem;
    const orderLineTotal = getTotal(menuItem.price, orderQuantity);

    return (
        <Modal isOpen={isOpen} setIsOpen={handleGoBack} fullScreen>
            <div className="md:relative inline-block w-full max-w-md overflow-x-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-t-2xl md:rounded-2xl">
                <div className="h-screen relative sm:max-w-xl sm:mx-auto">
                    <div className={image ? 'absolute left-3 top-3' : 'ml-3 mt-3'}>
                        <button
                            type="reset"
                            onClick={handleGoBack}
                            className="bg-white text-black h-10 w-10 rounded-full cursor-pointer outline-none"
                        >
                            <svg
                                className="w-6 h-6 ml-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        {/*<button*/}
                        {/*    onClick={handleRemoveMenuItem}*/}
                        {/*    className="bg-red-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Supprimer*/}
                        {/*</button>*/}
                    </div>
                    {image && (
                        <div
                            className="flex items-end justify-end h-56 w-full bg-cover"
                            style={{ backgroundImage: `url("${image}")` }}
                        ></div>
                    )}
                    <div className="relative px-4 pb-10 bg-white md:mx-0 sm:py-10">
                        <div className="max-w-md mx-auto overflow-y-visible">
                            <div className="w-full max-w-sm mx-auto rounded-md relative overflow-x-hidden">
                                <div className="pr-5 py-3">
                                    <h3
                                        className="text-gray-700 uppercase font-semibold"
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '2',
                                            WebkitBoxOrient: 'vertical',
                                            paddingTop: !image ? 4 : 0
                                        }}
                                    >
                                        {title}
                                    </h3>
                                    <span className="text-gray-700 text-sm mt-2">{price} €</span>
                                    {quantity && (
                                        <span className="text-gray-500 text-sm mt-2"> • {quantity}</span>
                                    )}
                                    <div className="mt-2">
                                        <span className="text-gray-500 text-sm">{description}</span>
                                    </div>
                                </div>
                            </div>
                            {orderOnlineEnabled && !isClosedOrdersOnline ? (
                                <form onSubmit={handleNewMenuItemToCart} ref={formRef}>
                                    <div className="flex flex-col md:flex-row py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                                        <div className="flex flex-col">
                                            <div className="flex flex-col">
                                                <label
                                                    htmlFor="note"
                                                    className="mt-4 mb-1 uppercase text-grey-darker text-xs font-bold"
                                                >
                                                    Vos consignes
                                                </label>
                                                <textarea
                                                    name="note"
                                                    defaultValue={orderItem?.note}
                                                    placeholder="Ajoutez des consignes"
                                                    type="text"
                                                    className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 resize-none"
                                                    rows="4"
                                                ></textarea>
                                            </div>
                                            <div className="flex flex-col">
                                                <label
                                                    htmlFor="quantity"
                                                    className="mt-4 mb-1 uppercase text-grey-darker text-xs font-bold"
                                                >
                                                    Quantité
                                                </label>
                                                <div className="flex flex-row justify-center align-middle mt-1">
                                                    <button
                                                        data-action="decrement"
                                                        onClick={handleDecrementQuantity}
                                                        className={`bg-gray-200 text-black h-14 w-14 rounded-full cursor-pointer outline-none focus:outline-none border-none focus:ring-0`}
                                                    >
                                                        <span className="text-3xl font-light">−</span>
                                                    </button>
                                                    <div className="text-center font-semibold text-md w-16 h-14 flex justify-center items-center">
                                                        {orderQuantity}
                                                    </div>
                                                    <button
                                                        data-action="increment"
                                                        onClick={handleIncrementQuantity}
                                                        className={`bg-gray-200 text-black h-14 w-14 rounded-full cursor-pointer outline-none`}
                                                    >
                                                        <span className="text-3xl font-light">+</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 flex flex-col justify-center items-center space-x-2">
                                        {orderItem && (
                                            <button
                                                onClick={handleRemoveMenuItem}
                                                className="mx-auto lg:mx-0 transform hover:scale-105 text-red-700 hover:font-bold underline mb-4 px-8"
                                            >
                                                Supprimer de la commande
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            disabled={!orderItem && (!orderLineTotal || orderLineTotal <= 0)}
                                            className={`${
                                                orderLineTotal > 0 ? primaryColor : 'bg-gray-100'
                                            } ${
                                                orderLineTotal > 0 ? 'text-white' : 'text-gray-400'
                                            } flex justify-center items-center w-72 px-4 py-3 rounded-md focus:outline-none`}
                                        >
                                            {!orderItem || !orderLineTotal || orderLineTotal <= 0
                                                ? 'Ajouter à ma commande'
                                                : 'Enregistrer'}{' '}
                                            {orderLineTotal > 0 ? `· ${orderLineTotal} €` : ''}
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <div className="pt-6 flex items-center space-x-4">
                                    <button
                                        onClick={handleGoBack}
                                        type="reset"
                                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none"
                                    >
                                        <svg
                                            className="w-6 h-6 mr-3"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                        Retour
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DetailsMenuItemModal;
