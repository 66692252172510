import React, {useEffect, useRef, useState} from 'react'
import {getFirebase} from "../../utils/signin";
import {path} from "ramda";

const StoreShowcase = ({
                           store
                       }) => {
    const name = path(['name'], store);
    const cover = path(['cover', 'image'], store);
    const logo = path(['logo', 'image'], store);
    if (!name) return null;

    return (
        <div className="w-full mx-auto">
            {cover ? (
                <img
                    className="w-full h-52 md:h-80 object-cover"
                    src={cover}
                    alt="cover"
                />
            ) : (
                <div className="w-full h-52 md:h-80 bg-sapin-300"/>
            )}
            <div className='flex flex-row px-3 py-4 md:container md:mx-auto md:px-0 md:py-6'>
                <div className=''>
                    {logo && (
                        <img
                            className="w-16 h-16 object-cover rounded-full"
                            src={logo}
                            alt="logo"
                        />
                    )}
                </div>
                <div className='ml-5'>
                    <h1 className='font-bold text-2xl'>{name}</h1>
                </div>
            </div>
        </div>
    )
}

export default StoreShowcase;
