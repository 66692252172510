import {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {length} from "ramda";

import {DashboardContext} from '../components/DashboardLayout';
import AppContext from '../context/app';
import {isNilOrEmpty} from '../utils';


export const getOrderStateDescription = (state) => {
    switch (state) {
        case 'waiting':
            return 'Votre commande a bien été envoyée au restaurant.';
        case 'InProgress':
            return 'Votre commande est en cours de préparation.';
        case 'toCollect':
            return 'Votre commande est à récupérer.';
        case 'done':
            return 'Votre commande est close.';
        default:
            return '';
    }
};
export const getOrderStateLabel = (state) => {
    switch (state) {
        case 'waiting':
            return 'En Attente de Préparation';
        case 'InProgress':
            return 'En cours de Préparation';
        case 'toCollect':
            return 'À Récupérer';
        case 'done':
            return 'Commande Terminée';
        default:
            return '';
    }
};

const useOrders = ({handleShowError, handleShowPremiumError}) => {
    const {user, isSignedIn, userLimits} = useContext(AppContext);
    const {storeDoc, store} = useContext(DashboardContext);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    useEffect(() => {
        if (!user || !storeDoc) return;

        fetchOrders();
    }, [user, isSignedIn, storeDoc]);
    const fetchOrders = async () => {
        try {
            if (!isSignedIn || !user) return [];

            const ordersRef = storeDoc.collection('orders');
            const currentDate = moment().format('YYYY/MM/DD');
            const ordersQuery = ordersRef
                .where("storeAuthorId", "==", user.uid)
                .where('createdAtDay', '==', currentDate);
            await ordersQuery.onSnapshot((ordersQuerySnapshot) => {
                if (isNilOrEmpty(ordersQuerySnapshot.docs)) return;

                const newOrders = ordersQuerySnapshot.docs.map((doc) => {
                    const myOrder = doc.data();
                    return {
                        id: doc.id,
                        ...myOrder
                    };
                });
                setOrders((prevOrders) => {
                    console.log('fetchOrders', !isNilOrEmpty(prevOrders), length(newOrders), length(prevOrders))
                    const hasNewOrder = !isNilOrEmpty(prevOrders) && length(newOrders) !== length(prevOrders);
                    if (hasNewOrder) {
                        const audio = new Audio('/notification.wav');
                        audio.play();
                    }

                    return newOrders;
                });
            });
            setIsLoading(false);
        } catch (e) {
            console.log('fetchOrders err', user.uid, e);
            setIsLoadingMutation(false);
            handleShowError();
        }
    };
    const updateOrderState = async (orderId, state) => {
        if (!orderId) return;

        setIsLoadingMutation(true);
        const ordersRef = storeDoc.collection('orders');
        await ordersRef.doc(orderId).set(
            {
                state
            },
            {merge: true}
        );
        setIsLoadingMutation(false);
    };
    const updateOrderOnlineState = async ({isOpen}) => {
        setIsLoadingMutation(true);
        await storeDoc.set(
            {
                isClosedOrdersOnline: !isOpen,
            },
            {merge: true}
        );
        setIsLoadingMutation(false);
    }

    return {
        isLoading,
        isLoadingMutation,
        orders,
        isClosedOrdersOnline: store?.isClosedOrdersOnline,
        updateOrderState,
        updateOrderOnlineState
    };
};

export {useOrders};
