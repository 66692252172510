import { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby-link';

import { DashboardContext } from '../components/DashboardLayout';
import AppContext from '../context/app';

const getSearchWithNewFilter = (query, additionalQuery) => {
    if (!additionalQuery) return query;

    return query.where(...additionalQuery);
};

const useMenus = ({ handleShowError, handleShowPremiumError }) => {
    const { user, isSignedIn, isLoadingUser, userLimits } = useContext(AppContext);
    const { store, storeDoc } = useContext(DashboardContext);
    const [myMenus, setMyMenus] = useState([]);
    const [myMenusFiltered, setMyMenusFiltered] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    useEffect(() => {
        if (!user || !storeDoc) return;

        fetchMyMenus();
    }, [user, isSignedIn, storeDoc]);
    const fetchMyMenus = async () => {
        try {
            if (!isSignedIn || !user) return [];

            const menusRef = storeDoc.collection('menus');
            console.log('userLimits::', userLimits);
            const menusRefQuerySnapshot = await menusRef.limit(userLimits.numberOfMenus).orderBy('createdAt', 'asc').get();
            console.log('userLimits::menusRefQuerySnapshot', menusRefQuerySnapshot);
            setMyMenus(
                menusRefQuerySnapshot.docs.map((doc) => {
                    console.log(doc.id, ' => ', doc.data());
                    const menu = doc.data();
                    const { title } = menu;
                    return {
                        id: doc.id,
                        title
                    };
                })
            );
            setIsLoading(false);
        } catch (e) {
            console.log('userLimits::e', e);
            handleShowError();
        }
    };
    const handleCreateMenu = async (newMenu) => {
        try {
            if (!isSignedIn) throw 'error';
            if (myMenus.length >= userLimits.numberOfMenus) {
                handleShowPremiumError();
                return;
            }

            setIsLoadingMutation(true);
            const doc = await storeDoc.collection('menus').add(newMenu);
            setIsLoadingMutation(false);
            navigate(`/app/menus/${doc.id}?isNewMenu=true`);
        } catch (error) {
            console.error('Error adding document: ', error);
            handleShowError();
        }
    };

    return { isLoading, isLoadingMutation, myMenus, handleCreateMenu };
};

export { useMenus };
