import React, { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { Link } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';

import logoImg from '../images/logo.png';
import 'moment/locale/fr';

function Footer() {
    const { languages, changeLanguage, language } = useI18next();
    const { t } = useTranslation();
    useEffect(() => {
        moment.locale(language);
    }, [language]);

    return (
        <footer className="bg-white border-t-2 border-gray-100">
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="mr-4 mb-6 text-gray-800 mr-0 sm:mr-40">
                        <a
                            className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                            href="/"
                        >
                            <img className="h-16 fill-current inline" src={logoImg} />
                            eMenila
                        </a>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">{t('languages.label')}</p>
                        <ul className="list-reset mb-6">
                            {languages.map((lng) => (
                                <li key={lng} className="mt-2 inline-block mr-2 md:block md:mr-0">
                                    <div
                                        onClick={() => changeLanguage(lng)}
                                        className="no-underline cursor-pointer hover:underline text-gray-800 hover:text-orange-500"
                                    >
                                        {t(`languages.${lng}`)}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Entreprise</p>
                        <ul className="list-reset mb-6">
                            <Link
                                to="/blog"
                                className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                            >
                                Blog
                            </Link>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a
                                    href="#"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    About Us
                                </a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <div
                                    onClick={() => Crisp.chat.open()}
                                    className="no-underline cursor-pointer hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Contact
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Social</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a
                                    href="#"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a
                                    href="#"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Linkedin
                                </a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a
                                    href="#"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Twitter
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Legal</p>
                        <ul className="list-reset mb-6 text-gray-700">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/privacy-policy"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Politique de confidentialité
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/terms-of-use"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Conditions générales d'utilisation
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/terms-of-sales"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Conditions Générales de Vente
                                </Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    to="/legal-notice"
                                    className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                >
                                    Mentions légale
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
