import React, {useEffect, useRef, useState} from 'react';
import {navigate} from "gatsby";
import ScrollableAnchor, {configureAnchors} from 'react-scrollable-anchor'
import {length, pathOr, reduce} from "ramda";

import MenuItemViewOnly from "../components/MenuItemViewOnly";
import DetailsMenuItemModal from "../components/DetailsMenuItemModal";
import {isNilOrEmpty} from "../utils";
import CartModal from "../components/CartModal";
import {usePublicStore} from "../hooks/publicStore";
import StoreShowcase from "../components/StoreShowcase";
import appleWalletImg from "../images/apple-wallet.png";
import BlockedAccountModal from "../components/BlockedAccountModal";
import ConsumerAuthModal from "../components/ConsumerAuthModal";

configureAnchors({offset: -70, scrollDuration: 300})

export const skeletonProducts = [0, 0, 0, 0];

export const getTotal = (price = 0, quantity = 1) => {
    return quantity * price;
}

export const getTotalFromCartItems = (cartItems = []) => reduce((acc, curr) => {
    const price = pathOr(0, ['menuItem', 'price'], curr);
    const quantity = pathOr(1, ['quantity'], curr);
    const itemTotal = getTotal(price, quantity);
    return acc + itemTotal;
}, 0, cartItems);

function Store({slug: storeSlug, location, params}) {
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [primaryColor, setPrimaryColor] = useState('bg-black');
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenDetailsMenuItemModal, setIsOpenDetailsMenuItemModal] = useState(false);
    const [isOpenCartModal, setIsOpenCartModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPremiumError, setShowPremiumError] = useState(false);
    const [isShrunk, setShrunk] = useState(false);
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const scrolledRef = useRef(false);
    const hash = location.hash;
    const hashRef = useRef(hash);
    const hashFormatted = hash && hash.replace('#', '');
    console.log('store params', hashFormatted, storeSlug, location, params);
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const {
        menus,
        cart,
        isLoading,
        user,
        createOrder,
        getMenuItemFromCart,
        saveMenuItemToCart,
        getAppleLoyaltyCard,
        isClosedOrdersOnline,
        myOrders,
        store,
        storeLimits,
    } = usePublicStore({
        storeSlug,
        handleShowError
    });
    const orderOnlineEnabled = storeLimits?.onlineOrderEnabled && pathOr(false, ['onlineOrderEnabled'], store);
    const loyaltyCardEnabled = pathOr(false, ['loyaltyCardEnabled'], storeLimits);
    const isTrialOver = pathOr(false, ['isTrial'], storeLimits) && pathOr(0, ['remainingTrialDays'], storeLimits) <= 0;
    useEffect(() => {
        if (!hash) return;

        if (hashRef.current !== hash) {
            hashRef.current = hash;
            scrolledRef.current = false;
        }
        if (scrolledRef.current) return;

        const id = hash.replace('#', '');
        // const element = document.getElementById(`${id}-tab`);
        // if (!element) return;
        //
        // setTimeout(() => {
        //     element.scrollIntoView({behavior: 'smooth'});
        //     scrolledRef.current = true;
        // }, 200)
    });
    useEffect(() => {
        const onScroll = () => {
            // setShrunk(isShrunk => {
            //     if (!isShrunk && (document.body.scrollTop > 45 || document.documentElement.scrollTop > 45)) {
            //         return true;
            //     }
            //
            //     if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
            //         return false;
            //     }
            //
            //     return isShrunk;
            // });
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    const getOrderCTALabel = () => {
        if (!isNilOrEmpty(cart.items)) return 'Voir la commande';
        if (!isNilOrEmpty(myOrders) && length(myOrders) > 1) return 'Voir mes commandes';

        return 'Voir ma commande';
    }
    const getIsiOS = () => {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    const handleOpenCart = () => {
        setIsOpenCartModal(true);
        setIsOpenDetailsMenuItemModal(false);
    }
    const handleSubmitOrder = async (order) => {
        try {
            setIsLoadingMutation(true);
            await createOrder(order);
            setIsLoadingMutation(false);
        } catch (e) {
            console.log('err', e)
            setIsLoadingMutation(false);
            handleShowError();
        }
    }
    const handleDownloadAppleLoyaltyCard = async () => {
        try {
            setIsLoadingMutation(true);
            await getAppleLoyaltyCard();
            setIsLoadingMutation(false);
        } catch (e) {
            setIsLoadingMutation(false);
        }
    }
    const signInSuccessHandler = (authResult, redirectUrl) => {
        setIsOpenAuthModal(false);
    }
    const handleOpenAuthModal = () => {
        setIsOpenAuthModal(true);
    }
    const handleOpenDetailsMenuItemModal = async (menuItem) => {
        setSelectedMenuItem(menuItem);
        setIsOpenDetailsMenuItemModal(true);
        setIsOpenCartModal(false);
    }
    const handleSaveMenuItemToCart = async (menuItem, quantity, note) => {
        return await saveMenuItemToCart(menuItem, quantity, note);
    }

    return (
        <div>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="">
                <StoreShowcase store={store}/>
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" py-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-20">
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="space-y-2">
                                        <div className="h-6 gradient rounded w-4/6"/>
                                        <div className="flex justify-between mt-6">
                                            <div className="h-48 gradient rounded flex-1 mr-2"/>
                                            <div className="h-48 gradient rounded flex-1 ml-2"/>
                                        </div>
                                        <div className="flex justify-between mt-6">
                                            <div className="h-48 gradient rounded flex-1 mr-2"/>
                                            <div className="h-48 gradient rounded flex-1 ml-2"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (<div className="w-full mx-auto">
                            {isShrunk && (
                                <div
                                    className='visible md:invisible bg-white flex flex-row fixed top-0 left-0 overflow-x-auto whitespace-nowrap w-full pt-4 pb-2 z-50'>
                                    {menus.map(({title, slug}) => (
                                        <div
                                            className={hashFormatted === slug ?
                                                'pb-4 border-b-4 border-black' :
                                                'pb-4 border-b-4 border-gray-300'
                                            }
                                        >
                                            <a
                                                href={`#${slug}`}
                                                id={`${slug}-tab`}
                                                className={'px-3 font-semibold text-sm'}
                                            > {title} </a>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {menus.map(({title, slug, menuItems = []}) => (
                                <ScrollableAnchor id={slug}>
                                    <div className="mt-6">
                                        <div className="mt-2">
                                            <label className="text-2xl font-bold" htmlFor="count">{title}</label>
                                        </div>
                                        <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                                            {isLoading && (
                                                skeletonProducts.map(() => (
                                                    <div className=" p-4 max-w-2xl w-full mx-auto">
                                                        <div className="animate-pulse flex space-x-40">
                                                            <div className="gradient h-72 w-full"></div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                            {menuItems.filter(({image}) => !!image).map((menuItem) => {
                                                const {title, price, image, id, description, quantity} = menuItem;
                                                return (
                                                    <MenuItemViewOnly
                                                        key={id}
                                                        title={title}
                                                        price={price}
                                                        image={image}
                                                        id={id}
                                                        description={description}
                                                        quantity={quantity}
                                                        menuItem={menuItem}
                                                        handleOpenDetailsMenuItemModal={handleOpenDetailsMenuItemModal}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div className="grid gap-4 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 pb-6">
                                            {menuItems.filter(({image}) => !image).map((menuItem) => {
                                                const {title, price, image, id, description, quantity} = menuItem;
                                                return (
                                                    <MenuItemViewOnly
                                                        key={id}
                                                        title={title}
                                                        price={price}
                                                        image={image}
                                                        id={id}
                                                        description={description}
                                                        quantity={quantity}
                                                        menuItem={menuItem}
                                                        handleOpenDetailsMenuItemModal={handleOpenDetailsMenuItemModal}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </ScrollableAnchor>
                            ))}
                        </div>
                    )}
                </div>
                {store?.name && loyaltyCardEnabled && !isLoading ? (
                    <div className='gradient px-3 py-4 min-h-52 items-center flex justify-center pb-32'>
                        <div className='flex-1 text-center'>
                            <label className="text-2xl font-semibold text-white" htmlFor="count">Obtiens la carte de
                                fidélité {store?.name}</label>
                        </div>
                        <div className='flex-1'>
                            {getIsiOS() && (
                                <div className="flex space-x-40 items-center justify-center mt-4">
                                    <img className="w-full max-h-16" src={appleWalletImg}
                                         onClick={handleDownloadAppleLoyaltyCard}/>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='pb-24'/>
                )}
            </div>
            {(!isNilOrEmpty(cart.items) || !isNilOrEmpty(myOrders)) && !isOpenDetailsMenuItemModal && !isOpenCartModal && (
                <div className="fixed bottom-10 flex justify-center items-center w-full z-50">
                    <button
                        onClick={handleOpenCart}
                        className={`${primaryColor} text-white flex justify-center items-center w-72 px-4 py-3 rounded-md focus:outline-none`}
                    >
                        {getOrderCTALabel()}
                    </button>
                </div>
            )}
            {!isLoading && !user?.uid && orderOnlineEnabled && !isTrialOver &&
                isNilOrEmpty(cart.items) && !isOpenDetailsMenuItemModal && !isOpenCartModal && (
                    <div className="fixed bottom-10 flex justify-center items-center w-full z-50">
                        <button
                            onClick={handleOpenAuthModal}
                            className={`bg-black text-white flex justify-center items-center w-72 px-4 py-3 rounded-md focus:outline-none`}
                        >
                            Une commande en cours ?
                        </button>
                    </div>
                )}
            <DetailsMenuItemModal
                isOpen={isOpenDetailsMenuItemModal}
                setIsOpen={setIsOpenDetailsMenuItemModal}
                menuItem={selectedMenuItem}
                orderItem={getMenuItemFromCart(selectedMenuItem?.id)}
                orderOnlineEnabled={orderOnlineEnabled}
                handleSaveMenuItemToCart={handleSaveMenuItemToCart}
                primaryColor={primaryColor}
                isClosedOrdersOnline={isClosedOrdersOnline}
            />
            <CartModal
                storePhoneNumber={store?.phoneNumber}
                isOpen={orderOnlineEnabled && isOpenCartModal}
                setIsOpen={setIsOpenCartModal}
                isClosedOrdersOnline={isClosedOrdersOnline}
                cart={cart}
                primaryColor={primaryColor}
                handleOpenDetailsMenuItemModal={handleOpenDetailsMenuItemModal}
                handleSubmitOrder={handleSubmitOrder}
                myOrders={myOrders}
            />
            <ConsumerAuthModal signInSuccessHandler={signInSuccessHandler} isOpen={isOpenAuthModal}
                               setIsOpen={setIsOpenAuthModal}/>
            <BlockedAccountModal isOpen={!isLoading && store?.name && isTrialOver} setIsOpen={() => true}/>
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </div>
    );
}

export default Store;
