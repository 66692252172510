import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { isNilOrEmpty } from '../../utils';
import { getConsumerUiConfig, getFirebase } from '../../utils/signin';
import Modal from '../Modal';

const setUILanguage = (t) => {
    const titleElem = document.querySelector(
        '#firebaseui_container > div > form > div.firebaseui-card-header > h1'
    );
    const phoneNumberLabelElem = document.querySelector(
        '#firebaseui_container > div > form > div.firebaseui-card-content > div > div.firebaseui-phone-number > div > label'
    );
    const tosElem = document.querySelector(
        '#firebaseui_container > div > form > div.firebaseui-card-footer > p'
    );
    const buttonElem = document.querySelector(
        '#firebaseui_container > div > form > div.firebaseui-card-actions > div > button'
    );

    if (titleElem) titleElem.textContent = t('phoneSignIn.title');
    if (phoneNumberLabelElem) phoneNumberLabelElem.textContent = t('phoneSignIn.number');
    if (buttonElem) buttonElem.textContent = t('phoneSignIn.button');
    if (tosElem) tosElem.textContent = t('phoneSignIn.tos');
};

const ConsumerAuthModal = ({ isOpen = false, setIsOpen, signInSuccessHandler = Function.prototype }) => {
    const { t, i18n } = useTranslation('firebase');
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    // Listen to the Firebase Auth state and set the local state.
    const firebase = getFirebase();
    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                console.log('unregisterAuthObserver', user);
                setIsSignedIn(!!user);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    useEffect(() => {
        if (!isOpen) return;
        setTimeout(() => {
            if (i18n.language == 'en') return;
            setUILanguage(t);
        }, 100);
    }, [isOpen]);
    if (isNilOrEmpty(firebase)) return null;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="inline-block z-50 w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="p-6">
                    <Dialog.Title as="h3" className="text-2xl font-large leading-6 text-gray-900">
                        {t('auth.title')}
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">{t('auth.desc')}</p>
                    </div>
                </div>

                <div>
                    <StyledFirebaseAuth
                        uiConfig={getConsumerUiConfig(signInSuccessHandler)}
                        firebaseAuth={getFirebase().auth()}
                    />
                </div>
                <div className="p-6">
                    <div className="mt-4">
                        <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClose={() => setIsOpen(false)}
                            onClick={() => setIsOpen(false)}
                        >
                            {t('close')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConsumerAuthModal;
