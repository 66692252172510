import PropTypes from "prop-types";
import React from "react";

function MenuItemViewOnly({
                              title,
                              price,
                              image,
                              id,
                              handleOpenDetailsMenuItemModal,
                              quantity,
                              description,
                              menuItem,
                          }) {

    return (
        <div
            onClick={() => handleOpenDetailsMenuItemModal(menuItem)}
            className="w-full max-w-sm mx-auto rounded-sm relative overflow-hidden"
        >
            {image && (
                <div className="flex items-end justify-end h-32 lg:h-56 w-full bg-cover"
                     style={{backgroundImage: `url("${image}")`}}>
                </div>
            )}
            <div className="pr-5 pt-1 pb-3">
                <h3 className="text-gray-700 uppercase font-semibold" style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{title}</h3>
                <span className="text-gray-700 text-sm">{price} €</span>
                {quantity && (
                    <span className="text-gray-500 text-sm"> • {quantity}</span>
                )}
                <div className={image ? 'hidden lg:block' : 'block'}>
                    <span className="text-gray-500 text-sm">{description}</span>
                </div>
            </div>
        </div>
    );
}

MenuItemViewOnly.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string
};

MenuItemViewOnly.defaultProps = {
    title: '',
    price: ''
};

export default MenuItemViewOnly;
