import React from 'react';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';

const Modal = ({ isOpen = false, setIsOpen, children, fullScreen = false }) => (
    <Dialog open={isOpen} className="fixed inset-0 z-50" onClose={() => setIsOpen(false)}>
        <Dialog.Panel>
            <div className={classNames('min-h-screen text-center', { 'px-4': !fullScreen })}>
                <Dialog.Overlay className="fixed inset-0 bg-black" style={{ opacity: 0.5, zIndex: -1 }} />
                <span className="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                </span>
                {children}
            </div>
        </Dialog.Panel>
    </Dialog>
);
export default Modal;
