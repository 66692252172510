import { useContext, useEffect, useState } from 'react';
import mime from 'mime';

import AppContext from '../context/app';
import { handleSortItemsByPosition } from '../helpers/menus';
import { getFirebase } from '../utils/signin';
import { useStore } from './store';

const useMenu = ({ menuId, handleShowError, handleShowPremiumError }) => {
    const { user, isSignedIn, userLimits } = useContext(AppContext);
    const { storeDoc } = useStore();
    const [myMenu, setMyMenu] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    useEffect(() => {
        if (!user || !storeDoc) return;

        fetchMyMenu();
    }, [user, isSignedIn, storeDoc, menuId]);
    const fetchMyMenu = async () => {
        try {
            if (!isSignedIn || !user) return [];

            const menusRef = storeDoc.collection('menus');
            const doc = await menusRef.doc(menuId).get();
            if (!doc.exists) {
                return; //error;
            }

            const { title, description, shoppingListId } = doc.data();
            setMyMenu({
                id: menuId,
                title,
                description,
                shoppingListId
            });
            await fetchMenuItems();
            setIsLoading(false);
        } catch (e) {
            setIsLoadingMutation(false);
            handleShowError();
        }
    };
    const fetchMenuItems = async () => {
        const menuItemsRef = storeDoc.collection('menu-items');
        const querySnapshot = await menuItemsRef
            .where('menusId', 'array-contains', menuId)
            .limit(userLimits.numberOfMenuItems)
            .get();
        const menuItems = querySnapshot.docs.map((doc) => {
            console.log(doc.id, ' => ', doc.data());
            const menuItem = doc.data();
            const { title, price, url, image, imagePath, tags, description, quantity, position, isHidden } = menuItem;
            return {
                id: doc.id,
                title,
                price,
                tags,
                url,
                image,
                imagePath,
                description,
                quantity,
                position,
                isHidden
            };
        });
        const menuItemsSorted = handleSortItemsByPosition(menuItems);
        setMenuItems(menuItemsSorted);
    };
    const removeMenu = async (menuId) => {
        setIsLoadingMutation(true);
        const menusRef = storeDoc.collection('menus');
        await menusRef.doc(menuId).delete();
        setIsLoadingMutation(false);
    };
    const uploadImage = async (image, menuItemId) => {
        if (!image) return {};

        const storageRef = getFirebase().storage().ref();
        const extension = mime.getExtension(image.file.type);
        const imagePath = `images/${user.uid}/${menuId}/${menuItemId}.${extension}`;
        const imageStorageRef = storageRef.child(imagePath);
        const snapshot = await imageStorageRef.put(image.file);
        const imageUrl = await snapshot.ref.getDownloadURL();
        return {
            imagePath,
            image: imageUrl
        };
    };
    const updateMenuItem = async (menuItem, image, menuItemId) => {
        if (!menuItemId) return;

        setIsLoadingMutation(true);
        const imageMaybe = await uploadImage(image, menuItemId);
        const menuItemsRef = storeDoc.collection('menu-items');
        await menuItemsRef.doc(menuItemId).set(
            {
                ...menuItem,
                ...imageMaybe
            },
            { merge: true }
        );
        await fetchMenuItems();
        setIsLoadingMutation(false);
    };
    const createMenuItem = async (newMenuItem, image, menuItemId) => {
        if (menuItems.length >= userLimits.numberOfMenuItems) {
            handleShowPremiumError();
            return;
        }

        setIsLoadingMutation(true);
        const menuItemsRef = storeDoc.collection('menu-items');
        const doc = await menuItemsRef.add(newMenuItem);
        if (image) {
            await updateMenuItem({}, image, doc.id);
        }
        await fetchMenuItems();
        setIsLoadingMutation(false);
    };
    const handleRemoveImage = async (imagePath) => {
        if (!imagePath) return null;

        const storageRef = getFirebase().storage().ref();
        const imageStorageRef = storageRef.child(imagePath);
        await imageStorageRef.delete();
    };
    const removeMenuItem = async (menuItemSelected) => {
        setIsLoadingMutation(true);
        await handleRemoveImage(menuItemSelected?.imagePath);
        const menuItemsRef = storeDoc.collection('menu-items');
        await menuItemsRef.doc(menuItemSelected.id).delete();
        await fetchMenuItems();
        setIsLoadingMutation(false);
    };
    const updateMenu = async (menu) => {
        setIsLoadingMutation(true);
        const menusRef = storeDoc.collection('menus');
        await menusRef.doc(menuId).set(
            {
                ...menu
            },
            { merge: true }
        );
        window.location.reload();
        setIsLoadingMutation(false);
    };

    return {
        isLoading,
        isLoadingMutation,
        myMenu,
        menuItems,
        setMenuItems,
        createMenuItem,
        updateMenuItem,
        removeMenuItem,
        removeMenu,
        updateMenu
    };
};

export { useMenu };
