import React from "react";
import {Link} from "gatsby";

import Modal from "../Modal";
import womanMeditatingImg from '../../images/woman-meditating.png'

const BlockedAccountModal = ({
                            isOpen = false,
                            setIsOpen,
                            text,
                        }) => {
    const handleGoBack = () => {
        setIsOpen(false);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={handleGoBack}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className={"ml-3 mt-3"}>
                        <button
                            type="reset"
                            onClick={handleGoBack}
                            className={`bg-white text-black h-10 w-10 rounded-full cursor-pointer outline-none`}>
                            <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                    <div className='overflow-y-auto' style={{maxHeight: '42rem'}}>
                        <section className="p-10 flex flex-col items-center justify-center">
                            <div className="pb-3 flex flex-col items-center justify-center">
                                <img src={womanMeditatingImg} alt={'be premium image'}/>
                                <h3 className="mt-10 text-gray-700 font-semibold text-xl text-center">
                                    Oops, une erreur est survenue, Veuillez prévenir le restaurateur svp 🙂
                                </h3>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BlockedAccountModal;
