import { useEffect, useState } from 'react';
import { path } from 'ramda';

import { getUserLimits } from '../client/dashboard/account';
import { isNilOrEmpty } from '../utils';
import { getFirebase } from '../utils/signin';

const useUser = () => {
    const firebase = getFirebase();
    const [user, setUser] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [userLimits, setUserLimits] = useState({});

    useEffect(() => {
        if (process.env.GATSBY_NODE_ENV != 'development') return;
        firebase.functions().useEmulator('localhost', 5001);
    }, []);

    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged(async (newUser) => {
                setUser(newUser);
                const createdAt = path(['metadata', 'createdAt'], newUser);
                const limits = newUser && (await getUserLimits(newUser.uid, parseInt(createdAt)));
                console.log('user::limits', createdAt, limits);
                setUserLimits(limits);
                setIsSignedIn(!!newUser);
                setIsLoadingUser(false);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const setAffiliateLink = (affiliateLink) => {
        setUserLimits({...userLimits, affiliateLink})
    }

    return { user, isSignedIn, isLoadingUser, userLimits, setAffiliateLink };
};

export { useUser };
