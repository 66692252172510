// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import {pathOr, reduce} from 'ramda';
import {navigate} from 'gatsby';
import moment from 'moment';

import Signedlayout, {accountTab} from '../../components/Signedlayout';
import {getFirebase} from '../../utils/signin';
import {getLimitsByOffer} from '../../utils/store';

const trialDaysPeriod = 14;

const getTrialLimits = (createdAt) => {
    const trialExpireAt = moment(createdAt).add(trialDaysPeriod, 'd');
    const remainingTrialDays = trialExpireAt.diff(moment(), 'd');
    return {
        isTrial: true,
        remainingTrialDays: isNaN(remainingTrialDays) || remainingTrialDays < 0 ? 0 : remainingTrialDays,
        onlineOrderEnabled: true,
        loyaltyCardEnabled: true,
        numberOfMenus: 1,
        numberOfMenuItems: 15,
        preventAllergiesEnabled: true,
        images: true
    };
};

const extrasCapabilities = {
    onlineOrder: {
        onlineOrderEnabled: true
    },
    loyaltyCard: {
        loyaltyCardEnabled: true
    }
};

export const getCapabilitiesByExtras = (extras = []) => {
    return reduce(
        (acc, curr) => {
            const extraCapabilities = extrasCapabilities[curr];
            if (!extraCapabilities) return acc;

            return {
                ...acc,
                ...extraCapabilities
            };
        },
        {},
        extras
    );
};

export const getUserLimits = async (userId, createdAt) => {
    const db = getFirebase().firestore();
    try {
        const customerRef = await db
            .collection('customers')
            .doc(userId);
        const customer = await (await customerRef.get()).data();
        const affiliateLink = pathOr(null, ['affiliateLink'], customer);
        const snapshot = await customerRef
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .where('cancel_at', '==', null)
            .get();
        const isCreatedUnder7days = moment(createdAt).isAfter(moment().subtract(trialDaysPeriod, 'd'));
        if (snapshot.empty) {
            // if (!isCreatedUnder7days) return null;

            return getTrialLimits(createdAt);
        }

        const subscription = snapshot.docs[0].data();
        const role = subscription.role;
        const extras = subscription.metadata?.extras;
        console.log(
            'getUserLimits',
            snapshot.docs,
            subscription,
            subscription.metadata,
            getLimitsByOffer(role, extras)
        );
        return getLimitsByOffer(role, extras, affiliateLink);
    } catch (e) {
        return null;
    }
};

function Account({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [userId, setUserId] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [myProject, setMyProject] = useState(null);
    const [products, setProducts] = useState([]);
    const [totalProductsPrice, setTotalProductsPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showCopiedUrl, setShowCopiedUrl] = useState(false);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenDeleteProjectModal, setIsOpenDeleteProjectModal] = useState(false);
    const [hasNoActivePlan, setHasNoActivePlan] = useState(false);
    const [userOffer, setUserOffer] = useState(null);
    const [userOfferPrice, setUserOfferPrice] = useState(null);
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
                setUserId(user.uid);
                setUserEmail(user.email);
                const db = getFirebase().firestore();
                db.collection('customers')
                    .doc(user.uid)
                    .collection('subscriptions')
                    .where('status', 'in', ['trialing', 'active'])
                    .onSnapshot(async (snapshot) => {
                        if (snapshot.empty) {
                            setHasNoActivePlan(true);
                            setIsLoading(false);
                            return;
                        }
                        const subscription = snapshot.docs[0].data();
                        console.log('subscription', subscription);
                        const priceData = (await subscription.price.get()).data();
                        setUserOffer(subscription.role);
                        setUserOfferPrice(
                            priceData
                                ? `${new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: priceData?.currency ? priceData?.currency : 'eur'
                                }).format((priceData.unit_amount / 100).toFixed(2))} par ${
                                    priceData.interval
                                }`
                                : 'N/A'
                        );
                        setIsLoading(false);
                    });
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    };
    const handleLogOut = () => {
        getFirebase().auth().signOut();
        navigate('/');
    };
    const handleOpenClientPortal = async () => {
        try {
            setIsLoadingMutation(true);
            const functionRef = getFirebase()
                .app()
                .functions('us-east1')
                .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
            const {data} = await functionRef({
                returnUrl: window.location.origin
            });
            window.location.assign(data.url);
            setIsLoadingMutation(false);
        } catch (e) {
            handleShowError();
        }
    };
    return (
        <div>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg
                        className="animate-spin ml-1 mr-3 h-28 w-28 text-sapin-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            )}
            <div className="pt-4 pb-24">
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-32 w-full rounded"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full mx-auto">
                            <div>
                                <p>
                                    Bonjour <b>{getFirebase().auth()?.currentUser?.displayName}</b>!
                                </p>
                                <br/>
                                {hasNoActivePlan ? (
                                    <p>
                                        Vous n'avez pas encore choisis d'abonnement. Cliquez ici pour changer
                                        cela.
                                    </p>
                                ) : (
                                    <div>
                                        <p>
                                            Votre avez choisis l'abonnement {userOffer} et payez{' '}
                                            {userOfferPrice}.
                                        </p>

                                        <h3 className="mt-6 text-base font-bold">
                                            Voir mes factures, modifier mon abonnement ou ma méthode de
                                            paiement.
                                        </h3>
                                        <button
                                            className="mt-2 bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 border border-yellow-400 rounded"
                                            onClick={handleOpenClientPortal}
                                        >
                                            Accéder à mon espace client
                                        </button>
                                    </div>
                                )}
                                <div className="flex justify-end">
                                    <button
                                        className="mt-8 bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                        onClick={handleLogOut}
                                    >
                                        Se déconnecter
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert"
                >
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">
                        Une erreur est survenue, veuillez réessayer plus tard.
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg
                            className="fill-current h-6 w-6 text-red-500"
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <title>Close</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
}

export default Account;
