import moment from 'moment';

const trialDaysPeriod = 14;
export const getTrialLimits = (createdAt) => {
    const trialExpireAt = moment(createdAt).add(trialDaysPeriod, 'd');
    const remainingTrialDays = trialExpireAt.diff(moment(), 'd');
    return {
        isTrial: true,
        remainingTrialDays: remainingTrialDays < 0 ? 0 : remainingTrialDays,
        onlineOrderEnabled: true,
        loyaltyCardEnabled: true,
        numberOfMenus: 1,
        numberOfMenuItems: 15,
        preventAllergiesEnabled: true,
        images: true
    };
};

export const getLimitsByOffer = (offer, extrasRaw = '{}', affiliateLink = null) => {
    const extras = JSON.parse(extrasRaw);
    switch (offer) {
        case 'dish':
            return {
                ...extras,
                offer,
                numberOfMenus: 5000,
                numberOfMenuItems: 5000,
                preventAllergiesEnabled: true,
                images: false,
                affiliateLink,
            };
        case 'dessert':
            return {
                ...extras,
                offer,
                affiliateLink,
                numberOfMenus: 5000,
                numberOfMenuItems: 5000,
                preventAllergiesEnabled: true,
                loyaltyCardEnabled: true,
                images: true
            };
        default:
            return {
                ...extras,
                offer,
                affiliateLink,
                numberOfMenus: 1,
                numberOfMenuItems: 15,
                preventAllergiesEnabled: false,
                images: false
            };
    }
};
