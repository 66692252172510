import { useContext, useEffect, useState } from 'react';
import { find, filter, head, pathOr, reject, isNil } from 'ramda';
import moment from 'moment';
import slugify from 'slugify';

import { getTotalFromCartItems } from '../client/store';
import AppContext from '../context/app';
import { handleSortItemsByPosition } from '../helpers/menus';
import { isNilOrEmpty } from '../utils';
import { getFirebase } from '../utils/signin';
import { getLimitsByOffer, getTrialLimits } from '../utils/store';

const baseCart = {
    items: [],
    note: '',
    total: 0,
    state: 'draft',
    transitions: []
};

const usePublicStore = ({ storeSlug }) => {
    const firebase = getFirebase();
    const { user, isSignedIn, isLoadingUser, userLimits } = useContext(AppContext);
    const [store, setStore] = useState(null);
    const [storeDoc, setStoreDoc] = useState(null);
    const [menus, setMenus] = useState([]);
    const [cart, setCart] = useState(baseCart);
    const [myOrders, setMyOrders] = useState([]);
    const [storeLimits, setStoreLimits] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    useEffect(() => {
        (async () => {
            const db = firebase.firestore();
            const storesRef = await db.collection('stores');
            await storesRef.where('slug', '==', storeSlug).onSnapshot(async (querySnapshot) => {
                if (isNilOrEmpty(querySnapshot.docs)) return;

                const doc = head(querySnapshot.docs);
                const store = doc.ref;
                setStore(doc.data());
                setStoreDoc(store);
                const storeLimits = getStoreLimits(doc.data());
                setStoreLimits(storeLimits);
                console.log('storeLimits::', storeLimits);
                await fetchMenus(store, storeLimits);
                setIsLoading(false);
            });
        })();
    }, [storeSlug]);
    useEffect(() => {
        if (!isSignedIn) return;

        (async () => {
            await fetchMyOrders();
        })();
    }, [isSignedIn]);
    const fetchMyOrders = async (store) => {
        if (!user?.uid) return;

        const storeRef = store ?? storeDoc;
        const ordersRef = storeRef.collection('orders');
        const currentDate = moment().format('YYYY/MM/DD');
        await ordersRef
            .where('createdAtDay', '==', currentDate)
            .where('authorId', '==', user.uid)
            .onSnapshot(async (querySnapshot) => {
                if (isNilOrEmpty(querySnapshot.docs)) return;

                const myOrders = querySnapshot.docs.map((doc) => {
                    const myOrder = doc.data();
                    return {
                        id: doc.id,
                        ...myOrder
                    };
                });
                setMyOrders(myOrders);
            });
    };
    const fetchMenus = async (store, storeLimits) => {
        const numberOfMenusLimits = pathOr(1, ['numberOfMenus'], storeLimits);
        const storeRef = store ?? storeDoc;
        const menusRef = storeRef.collection('menus');
        const menusQuerySnapshot = await menusRef
            .limit(numberOfMenusLimits)
            .orderBy('createdAt', 'asc')
            .get();
        const menusPromises = menusQuerySnapshot.docs.map(async (doc) => {
            const id = doc.id;
            const menu = doc.data();
            const menuItems = await fetchMenuItems(id, storeRef, storeLimits);
            return {
                id,
                slug: slugify(menu.title),
                ...menu,
                menuItems
            };
        });
        const menus = await Promise.all(menusPromises);
        setMenus(menus);
    };
    const fetchMenuItems = async (menuId, store, storeLimits) => {
        const storeRef = store ?? storeDoc;
        const numberOfMenuItemsLimits = pathOr(15, ['numberOfMenuItems'], storeLimits);
        const preventAllergiesEnabled = pathOr(false, ['preventAllergiesEnabled'], storeLimits);
        const imagesEnabled = pathOr(false, ['images'], storeLimits);
        console.log('storeLimits::fetchMenuItems', imagesEnabled, storeLimits);
        const menuItemsRef = storeRef.collection('menu-items');
        const querySnapshot = await menuItemsRef
            .where('menusId', 'array-contains', menuId)
            .limit(numberOfMenuItemsLimits)
            .get();
        const menuItems = querySnapshot.docs.map((doc) => {
            const menuItem = doc.data();
            const { title, price, url, image, imagePath, tags, description, quantity, position, isHidden } =
                menuItem;
            const imageMaybe = imagesEnabled
                ? {
                      image,
                      imagePath
                  }
                : {};
            return {
                id: doc.id,
                title,
                price,
                tags,
                url,
                ...imageMaybe,
                description,
                quantity,
                position,
                isHidden
            };
        });
        const menuItemsVisible = filter(({ isHidden }) => !isHidden, menuItems);
        const menuItemsSorted = handleSortItemsByPosition(menuItemsVisible);
        return menuItemsSorted;
    };
    const getMenuItemFromCart = (id) =>
        id && find(({ menuItemId }) => menuItemId === id, pathOr([], ['items'], cart));
    const rejectUndefined = reject((attr) => !attr);
    const saveMenuItemToCart = async (menuItemRaw, quantity, note) => {
        const menuItem = rejectUndefined(menuItemRaw);
        const { id } = menuItem;
        const cartItems = pathOr([], ['items'], cart);
        const item = getMenuItemFromCart(id);
        const itemToSave = item
            ? {
                  ...item,
                  quantity,
                  note
              }
            : {
                  menuItemId: id,
                  menuItem,
                  quantity,
                  note
              };
        const rejectNoQuantity = reject(({ quantity }) => quantity <= 0);
        const newCartItems = rejectNoQuantity([
            itemToSave,
            ...reject(({ menuItemId }) => menuItemId === id, cartItems)
        ]);
        const total = getTotalFromCartItems(newCartItems);
        const newCart = {
            ...cart,
            total,
            items: newCartItems
        };
        setCart(newCart);
    };
    const getFreeDisplayId = async (numberOfTry = 0) => {
        const currentDate = moment().format('YYYY/MM/DD');
        const displayIdsUsedRef = await storeDoc.collection('displayIdsUsed');
        const displayId = numberOfTry === 0 ? 9534 : Math.floor(1000 + Math.random() * 9000);
        const querySnapshot = await displayIdsUsedRef
            .where('createdAt', '==', currentDate)
            .where('displayId', '==', displayId)
            .get();
        const isFreeDisplayId = querySnapshot.docs.length <= 0;
        if (numberOfTry > 30) throw 'To much try to get free display Id';

        if (!isFreeDisplayId) {
            return await getFreeDisplayId(numberOfTry + 1);
        }

        await displayIdsUsedRef.add({
            authorId: user.uid,
            displayId,
            createdAt: currentDate
        });
        return displayId;
    };
    const createOrder = async (order) => {
        const ordersRef = await storeDoc.collection('orders');
        const displayId = await getFreeDisplayId();
        const createdAt = moment().toISOString();
        const currentDate = moment().format('YYYY/MM/DD');
        const newOrder = {
            ...order,
            authorId: user.uid,
            phoneNumber: user.phoneNumber,
            storeAuthorId: store.authorId,
            displayId,
            state: 'waiting',
            transitions: [
                {
                    state: 'waiting',
                    createdAt
                }
            ],
            createdAt,
            createdAtDay: currentDate
        };
        const docRef = await ordersRef.add(newOrder);
        const doc = await ordersRef.doc(docRef.id).get();
        setMyOrders([...myOrders, doc.data()]);
        setCart(baseCart);
    };
    const getAppleLoyaltyCard = async () => {
        setIsLoadingMutation(true);
        const accessToken = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(
            `${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/generateApplePassLoyaltyCard`,
            {
                method: 'POST',
                body: JSON.stringify({
                    storeId: storeDoc.id
                }),
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }
        );
        async function readAllChunks(readableStream) {
            const reader = readableStream.getReader();
            const chunks = [];

            let done, value;
            while (!done) {
                ({ value, done } = await reader.read());
                if (done) {
                    return chunks;
                }
                chunks.push(value);
            }
        }
        const data = await readAllChunks(response.body);
        // console.log('response after', data);
        const blob = new Blob(data, { type: 'application/vnd.apple.pkpass' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'emenila.pkpass';
        link.click();
        setIsLoadingMutation(false);
    };
    const getStoreLimits = (store) => {
        const { createdAt, sub = {} } = store;
        const { role, extras } = sub;
        const hasNoRole = isNil(role);

        if (hasNoRole) return getTrialLimits(createdAt);
        return getLimitsByOffer(role, extras);
    };

    return {
        isLoading,
        isLoadingMutation,
        menus,
        cart,
        store,
        myOrders,
        storeLimits,
        isClosedOrdersOnline: store?.isClosedOrdersOnline,
        createOrder,
        getMenuItemFromCart,
        saveMenuItemToCart,
        getAppleLoyaltyCard,
        user
    };
};

export { usePublicStore };
